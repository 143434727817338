<template>
  <v-navigation-drawer
  v-model="drawer"
    absolute
    dark
    right
    overlay-opacity="0"
    hide-overlay
    expand-on-hover
  >
    <v-list
      nav
      dense
    >
    <v-list-item link @click="ChangeURL('history')">
        <v-list-item-icon>
          <v-badge v-show="badgehistory" dot color="green"/>
          <v-icon color="#a10115">mdi-history</v-icon>
        </v-list-item-icon>
        <v-list-item-title><span style="color:#a10115;">History</span></v-list-item-title>
      </v-list-item>
      <v-list-item link @click="ChangeURL('Log')">
        <v-list-item-icon>
          <v-icon color="#a10115">mdi-account-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-title><span style="color:#a10115;">LOG</span></v-list-item-title>
      </v-list-item>
      <v-list-item link @click="ChangeURL('auth')">
        <v-list-item-icon>
          <v-icon color="#a10115">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title><span style="color:#a10115;">Log Out</span></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import API from "@/services/http";
import Vue from 'vue'
export default {
data(){
  return{
    drawer:true,
    badgehistory:false,
    user:JSON.parse(localStorage.getItem('logged')),
  }
},
created(){
 //
},
mounted(){
 Vue.prototype.$Drawer = this.Drawer
 this.$Drawer(true)
 this.cekhistory()
},
methods:{
  Drawer(){
    return this.drawer = !this.drawer
  },
  cekhistory(){
    API.get('seenhistory/'+this.user.id_user).then(x=>{
      if(x.data.seen == 'N'){
        this.badgehistory = false
      }
      if(x.data.seen == 'Y'){
        this.badgehistory = true
      }
    })
  },
  LogOut(){
    this.$setLogOut()
    // localStorage.removeItem('logged')
    // this.$router.push('/auth')
  },
  ChangeURL(x){
    if(x == 'auth'){
      return this.LogOut()
    }else{
      return this.$ChangeURL(x)
    }
  }, 
}
}
</script>
<style scoped>
</style>